<template>
  <div>
    <multiselect 
        v-model="selected" 
        label="value" 
        track-by="key" 
        placeholder="Type to search" 
        open-direction="bottom" 
        :options="foundOptions" 
        :multiple="multiple" 
        :searchable="true" 
        :loading="isLoading" 
        :internal-search="false" 
        :clear-on-select="clearOnSelect" 
        :close-on-select="!multiple" 
        :options-limit="300" 
        :limit="3" 
        :limit-text="limitText" 
        :max-height="600" 
        :show-no-results="false" 
        :hide-selected="true" 
        @search-change="find"
        @open="find"
      >

      <span slot="noResult">No results found</span>
    </multiselect> 
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios';
  import _ from 'lodash'

  export default {
    name: 'ApiLookup',
    components: {
      Multiselect
    },
    data () {
      return {
        foundOptions: [],
        isLoading: false,
        cancelToken: null,
        currentQuery: false,
      }
    },
    props: {
      source: [String, Array],
      value: [Array, Object],
      filter: { type: Object, default: () => { return {} } },
      context: String,
      multiple: { type: Boolean, default: () => { return true } },
      label: { type: String, default: () => { return 'value' } },
      keyname: { type: String, default: () => { return 'key' } },
      searchOnMount: { type: Boolean, default: () => { return false } }, 
      searchEmpty: { type: Boolean, default: () => { return false } }, 
      usePost: { type: Boolean, default: () => { return true } },
      clearOnSelect: { type: Boolean, default: () => { return false } },
    },
    watch: {
      async source () {
        this.foundOptions.splice(0)
        this.selected = null

        if (this.searchOnMount) {
          this.find('', true)
        }
      },
    },
    computed: {
      selected: {
        get () {
          console.log('getting', this.value)
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        }
      }
    },
    methods: {
        limitText (count) {
          return `and ${count} others`
        },
        find (query, force = false) {
          if (Array.isArray(this.source)) {
            query = query.toLowerCase()
            this.foundOptions = this.source.filter(i => i.value.toLowerCase().includes(query))
            return
          }
          this.asyncFind(query, force)
        },
        asyncFind: _.debounce(function (query, force = false) {
          if (this.currentQuery === query) {
            console.log(this.currentQuery, query)
            return
          }
          this.currentQuery = query

          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          
          this.foundOptions = []
          if (!this.searchOnMount && !force && !this.searchEmpty && query == '') {
            this.isLoading = false;
            return
          }

          this.cancelToken = axios.CancelToken.source()

          this.isLoading = true

          if (this.usePost) {
            this.$http
                .post(this.source, {
                    q: query,
                    filter: this.filter,
                    context: this.context
                  },{
                    cancelToken: this.cancelToken.token
                })
                .then(response => {
                  
                  this.foundOptions = response.data.map(i => {
                    return { key: i[this.keyname], value: i[this.label] }
                  })
                  this.isLoading = false
                }).catch(function () {
                })
          } else {
            this.$http
                .get(this.source, { params: {
                    q: query,
                    filter: this.filter,
                    context: this.context
                  } },{
                    cancelToken: this.cancelToken.token
                })
                .then(response => {
                  this.foundOptions = response.data.map(i => {
                    return { key: i[this.keyname], value: i[this.label] }
                  })
                  this.isLoading = false
                }).catch(function () {
                })            
          }
        }, 500),
        clearAll () {
          this.selected = []
        }
    },
    mounted () {
      if (this.searchOnMount) {
        this.find('', true)
      }
    }
  }
</script>

<style>

</style>
